import './loading.css'
import './loading-btn.css'
import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'gatsby'
import * as storage from '../utils/storage'

class EventComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      event: props.event,
      fetchingData: false
    }
  }

  handleRequestAccess() {
    const { event } = this.props
    this.setState({ fetchingData: true })
    axios
      .post(
        `${process.env.API_BASE_URL}/api/events/${event.id}/request-access`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + storage.getItem('access_token'),
          },
        }
      )
      .then(res => {
        this.setState((state) => ({
          fetchingData: false,
          event: {...state.event, ...{guestStatus: 'pending'}}
        }))
      })
      .catch(err => console.log(err))
  }

  handleOnLinkClick = (e) => {
    if (window.confirm('Do you want to purchase tickets to this event?')) {
      return true
    } else {
      e.preventDefault()
      return false
    }
  }

  render() {
    const { event, fetchingData } = this.state
    const { isPastEvent } = this.props
    return (
      <tr className='table-row'>
        <td>
          {new Date(event.starts).toLocaleDateString('en-AU', { month: 'short', day: 'numeric' })}
        </td>
        <td>
          <a href={event.url} target="_blank" onClick={this.handleOnLinkClick}>
            {event.name}
          </a>
        </td>
        <td>
          {isPastEvent && event.guestStatus && event.guestStatus === 'done_matching' ? (
              <center>
                <Link
                  className="button is-info"
                  to={`/event-results?id=${event.id}`}
                  style={{ width: '70%', fontSize: '12px' }}
                >
                  Result
                </Link>
              </center>
            ) : event.guestStatus && event.guestStatus === 'approved' && isPastEvent ? (
              <center>
                <Link
                  className="button is-success"
                  to={`/event-matching?id=${event.id}`}
                  style={{ width: '70%', fontSize: '12px' }}
                >
                  Start Matching
                </Link>
              </center>
            ) : event.guestStatus && event.guestStatus === 'approved' ? (
              <center>
                <button
                  className="button is-success"
                  style={{ width: '70%', fontSize: '12px' }}
                >
                  Access Approved
                </button>
              </center>
            ) : event.guestStatus && event.guestStatus === 'pending' ? (
              <center>
                <button
                  className="button is-danger"
                  style={{ width: '70%', fontSize: '12px' }}
                >
                  Pending
                </button>
              </center>
            ) : (
              <center>
                <button
                  className={
                    fetchingData
                      ? 'button is-light is-loading'
                      : 'button is-light'
                  }
                  onClick={e => this.handleRequestAccess()}
                  style={{ width: '70%', fontSize: '12px' }}
                >
                  {fetchingData ? (
                    <React.Fragment />
                  ) : (
                    'Request Access'
                  )}
                </button>
              </center>
          )}
        </td>
      </tr>
    )
  }
}

export default EventComponent