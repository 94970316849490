import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import * as storage from '../utils/storage'
import useInterval from '../utils/useInterval'
import EventComponent from './event'

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

const ComingEvents = () => {
  const [events, setEvents] = useState([]);
  const [lastRequestedAt, setLastRequestedAt] = useState(null);
  
  const fetchData = () => {
    setLastRequestedAt(moment().utc().format('YYYY-MM-DD HH:mm:ss'))
    axios
      .post(
        `${process.env.API_BASE_URL}/graphql`,
        {
          query: `query {
          me {
            id
            comingEvents(first: 50, soonestFirst: true) {
              edges {
                node {
                  id
                  name
                  url
                  status
                  starts
                  ends
                  guestStatus
                  attended
                }
              }
            }
          }
        }`,
        },
        {
          headers: {
            Authorization: 'Bearer ' + storage.getItem('access_token'),
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          },
        }
      )
      .then(res => {
        const myEvents = res.data.data.me.comingEvents.edges.map(
          edge => edge.node
        )
        const me = res.data.data.me.id

        axios
          .post(`${process.env.API_BASE_URL}/graphql`, {
            query: `query {
          events(first: 50, soonestFirst: true, status: "live", excludePast: true, user: ${me}) {
            edges {
              node {
                id
                name
                url
                status
                starts
                ends
              }
            }
          }
        }`,
          })
          .then(res => {
            const _events = res.data.data.events.edges.map(edge => {
              let myEvent = myEvents.find(item => item.id === edge.node.id)
              if (!myEvent) return edge.node
              return { ...edge.node, ...myEvent }
            })
            setEvents([]);
            setEvents(_events);
          })
          .catch(err => console.log(err))
      })
      .catch(err => console.log(err))
  }
  
  useEffect(() => {
    
    window.Pusher = Pusher;
 
    window.echo = new Echo({
        broadcaster: 'pusher',
        key: `${process.env.PUSHER_APP_KEY}`,
        cluster: `${process.env.PUSHER_APP_CLUSTER}`,
        forceTLS: true,
        authEndpoint: `${process.env.API_BASE_URL}/broadcasting/auth`,
        auth: {
          headers: {
              Authorization: "Bearer " + storage.getItem('access_token'),
              Accept: "application/json"
          }
        }
    });
    
    fetchData();
    
    const currentUser = JSON.parse(storage.getItem('user'));
    window.echo.private(`user-events.${currentUser.id}`)
      .listen('EventAccessRequestApproved', (e) => {
        fetchData();
    });
  }, []);
  
  useInterval(async () => {
    try {
      const response = await axios.get(`${process.env.API_BASE_URL}/api/ping/user-events-update`, 
        {
          headers: {
            Authorization: 'Bearer ' + storage.getItem('access_token'),
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          },
        }
      );
      
      if (moment(response.data.last_updated_at).isAfter(moment(lastRequestedAt))) {
        fetchData();
      }
    } catch (e) {
      console.log(e);
    }
  }, 1000 * 5);
  
  return (
    <React.Fragment>
      {events.map(event => (
        <EventComponent key={event.id} event={event} />
      ))}
    </React.Fragment>
  );
}

export default ComingEvents;
