import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import * as storage from '../utils/storage'
import useInterval from '../utils/useInterval'
import EventComponent from './event'

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

const PastEvents = () => {
  const [events, setEvents] = useState([]);
  const [lastRequestedAt, setLastRequestedAt] = useState(null);

  const getHoursPastDate = hoursBefore => {
    return new Date(new Date() - 1000 * 60 * 60 * hoursBefore)
  }
  
  const fetchData = () => {
    setLastRequestedAt(moment().utc().format('YYYY-MM-DD HH:mm:ss'))
    axios
      .post(
        `${process.env.API_BASE_URL}/graphql`,
        {
          query: `query {
          me {
              id
              pastEvents(first: 8) {
                edges {
                  node {
                    id
                    name
                    url
                    status
                    starts
                    ends
                    guestStatus
                    attended
                  }
                }
              }
          }
        }`,
        },
        {
          headers: {
            Authorization: 'Bearer ' + storage.getItem('access_token'),
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          },
        }
      )
      .then(res => {
        const pastEvents = res.data.data.me.pastEvents.edges.map(
          edge => edge.node
        )
        const me = res.data.data.me.id

        axios
          .post(`${process.env.API_BASE_URL}/graphql`, {
            query: `query {
          events(first: 3, status: "completed", excludePast: false, user: ${me}) {
            edges {
              node {
                id
                name
                url
                status
                starts
                ends
              }
            }
          }
        }`,
          })
          .then(res => {
            const _events = res.data.data.events.edges
              .map(edge => {
                let pastEvent = pastEvents.find(
                  item => item.id === edge.node.id
                )
                if (!pastEvent) return edge.node
                return { ...edge.node, ...pastEvent }
              })
              .filter(row => {
                return getHoursPastDate(48) <= new Date(row.ends)
              })
            setEvents([]);
            setEvents(_events);
          })
          .catch(err => console.log(err))
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    
    window.Pusher = Pusher;
 
    window.echo = new Echo({
        broadcaster: 'pusher',
        key: `${process.env.PUSHER_APP_KEY}`,
        cluster: `${process.env.PUSHER_APP_CLUSTER}`,
        forceTLS: true,
        authEndpoint: `${process.env.API_BASE_URL}/broadcasting/auth`,
        auth: {
          headers: {
              Authorization: "Bearer " + storage.getItem('access_token'),
              Accept: "application/json"
          }
        }
    });
    
    fetchData();
    
    const currentUser = JSON.parse(storage.getItem('user'));
    window.echo.private(`user-events.${currentUser.id}`)
      .listen('EventAccessRequestApproved', (e) => {
        fetchData();
    });
  }, []);
  
  useInterval(async () => {
    try {
      const response = await axios.get(`${process.env.API_BASE_URL}/api/ping/user-events-update`, 
        {
          headers: {
            Authorization: 'Bearer ' + storage.getItem('access_token'),
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          },
        }
      );
      if (moment(response.data.last_updated_at).isAfter(moment(lastRequestedAt))) {
        fetchData();
      }
    } catch (e) {
      console.log(e);
    }
  }, 1000 * 5);

  return (
    <React.Fragment>
      {events.map((event, i) => (
        <EventComponent key={i} event={event} isPastEvent />
      ))}
    </React.Fragment>
  )
}

export default PastEvents;
